import React from "react"
import { PhotoList } from "./photo-list"
import ViewAnimation from "../animation/view-animation"
import * as Styled from "./index.style"
import useDeviceDetect from "../../hooks/use-device-detect"

export const PhotoCategory = ({ title, description, images }) => {
  const { isMobile } = useDeviceDetect()

  return (
    <Styled.Container isMobile={isMobile}>
      {isMobile ? (
        <>
          <ViewAnimation>
            <Styled.Info>
              <Styled.Title>{title}</Styled.Title>
              <Styled.Description>{description}</Styled.Description>
            </Styled.Info>
          </ViewAnimation>
          <br />
          <PhotoList images={images} />
        </>
      ) : (
        <>
          <PhotoList images={images} />
          <ViewAnimation transitionDelay>
            <Styled.Info>
              <Styled.Title>{title}</Styled.Title>
              <Styled.Description>{description}</Styled.Description>
            </Styled.Info>
          </ViewAnimation>
        </>
      )}
    </Styled.Container>
  )
}
