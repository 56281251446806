import React from "react"
import styled from "styled-components"
import {
  Accordion,
  AccordionItemButton,
} from "react-accessible-accordion"

export const MapLegendWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  height: 100%;
`

export const MapLegendAccordion = styled(props => <Accordion {...props} />)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`

export const MapLegendAccordionItemTitle = styled(props => (
  <AccordionItemButton {...props} />
))`
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  opacity: 0.5;
  color: #47341e;
  cursor: pointer;
  text-transform: capitalize;
  ${({ $active }) => ($active ? `opacity: 1;` : ``)};
  &:last-child {
    margin-bottom: 0;
  }
`

export const MapLegendPoIWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  max-height: ${({ isMobile }) => !isMobile && 'calc(90vh - 240px)'};
  overflow: auto;
  flex-direction: column;
  animation: smoothOpen 5s;
`

export const MapLegendDiningPoIWrapper = styled.div`
  margin-top: 20px;
  max-height: ${({ isMobile }) => !isMobile && 'calc(90vh - 240px)'};
  overflow: auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const MapLegendDiningPoIBlock = styled.div`
  width: 100%;
`

export const MapLegendPoI = styled.div`
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 13px;
  line-height: 125%;
  color: #47341e;
  cursor: pointer;
    ${({ $active }) =>
    $active ? `text-decoration: underline;font-weight: bold;` : ``
  }
`
export const SubwayLinesTitle = styled.div`
  margin: 10px 0px;
  font-weight: 400;
  font-size: 11px;
  line-height: 125%;
  color: #47341e;
  cursor: pointer;
`
