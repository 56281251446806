import styled from "styled-components"

export const MapWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${({ isMobile }) => isMobile ? 'column' : 'row'};
  width: 100vw;
  margin-top: 5vh;
  margin-bottom: 5vh;
  overflow: hidden;
  z-index: 1;
 `

export const MapContentWrapper = styled.div`
  height: ${({ isMobile }) => isMobile ? '50vh' : '90vh'};;
  width: ${({ isMobile }) => isMobile ? '100%' : '60%'};
 `

export const MapLegendWrapper = styled.div`
  width: ${({ isMobile }) => isMobile ? '100%' : '40%'};
  max-height: ${({ isMobile }) => !isMobile && '90vh'}; ;
  margin-top:  ${({ isMobile }) => isMobile && '24px'};
  padding: 0 1%;
`