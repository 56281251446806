import styled from 'styled-components'

export const StyledContainer = styled.section`
  padding: ${({ isMobile }) => !isMobile && '20px'};
  margin-bottom: 40px;
`

export const Container = styled.div`
  display: grid;
  grid-template-columns: ${({ isMobile }) => isMobile ? '1fr' : '0.75fr 0.25fr'};
  position: relative;
  margin-top: 60px;
  grid-column-gap: 20px;
`

export const Title = styled.h5`
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #47341e;
  margin-bottom: 20px;
`

export const Info = styled.div`
  position: sticky;
  top: 0;
  padding-top: 60px;
  margin-top: -60px;
`

export const Description = styled.p`
  font-weight: 400;
  font-size: 13px;
  font-style: normal;
  line-height: 175%;
  color: #47341e;
`