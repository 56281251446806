import React from "react"
import SmoothScroll from "../helpers/smooth-scroll"
import PageAnimation from "../components/animation/page-animation"
import GlobalStyle from "../helpers/global-styles"
import { useStaticQuery, graphql } from "gatsby"
import { DefaultLayout } from "../components/layout"
import styled from "styled-components"
// import NeighborhoodPlaces from "../components/map-legend"
import Header from "../components/header"
import Intro from "../components/intro"
import PageDescription from "../components/page-description"
import Map from "../components/map"
import { NeighborhoodImages } from "../components/neighborhood-images"
import Layout from "../components/app-layout"
import Footer from "../components/footer"
import { LinkWrapper } from "../components/availability-table/index.style"
import CategoryLink from "../components/categories/category-link"
import useDeviceDetect from "../hooks/use-device-detect"
import Seo from "../components/seo"

const StyledContainer = styled.div`
  display: flex;
  padding-bottom: 20px;
  gap: 20px;
`
const Neighborhood = () => {
  const { isMobile } = useDeviceDetect()

  const query = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "neighborhood/intro/intro.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const description =
    'Centered in one of the most historically rich and culturally distinguished neighborhoods in the city, Ruby is mere moments away from Chelsea’s globally-celebrated art galleries, as well as some of New York’s greatest food and shopping destinations. With convenient access to public transportation and close proximity to parks and cultural attractions, the world is at your door.'
  return (
    <Layout pageName={"Neighborhood"}>
      <SmoothScroll>
        <PageAnimation>
          <GlobalStyle />
          <Header />
          <Intro query={query} isFullHeight={!isMobile} />
          <PageDescription text={description} />
          <DefaultLayout>
            <StyledContainer>
              <Map />
            </StyledContainer>
            <NeighborhoodImages />
            <LinkWrapper>
              <CategoryLink link={"/availability"} title={"AVAILABILITY"} />
            </LinkWrapper>
          </DefaultLayout>
          <Footer />
        </PageAnimation>
      </SmoothScroll>
    </Layout>
  )
}

export default Neighborhood

export const Head = () => <Seo title="Neighborhood" />
