import styled from "styled-components"
import { mediaMax } from "../../helpers/media-queries"

export const Wrapper = styled.div`
  position:relative;
`

export const Table = styled.table`
  width: 90%;
  margin: 0 auto;
  border-spacing: 0;
  border-collapse: collapse;
`

export const TableBodyRow = styled.tr`
   padding: 14px 0px;

    &:hover {
     background: #a1b38c;
  }

`
export const TableHead = styled.thead`
  border-top: 1px solid #47341E;
  border-bottom: 1px solid #47341E;
`
export const Cell = styled.th`
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px;
  line-height: 175%;
  letter-spacing: 0.22em;
  color: #47341e;
`

export const ItemCell = styled.span`
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px;
  line-height: 175%;
  letter-spacing: 0.22em;
  color: #47341e;
`
export const FloorPlanItemCell = styled(ItemCell)`
 display: flex;
`

export const HeadCell = styled(Cell)`
  padding: 4px;
`

export const View = styled.span`
  cursor: pointer;
`

export const ShareIconWrapper = styled(View)``

export const TableBodyCell = styled.th`
  outline: none;
  border: none;
  padding: 4px;
  text-align: left;
`

export const HeadCellItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  cursor: pointer;
`

export const ResidenceItem = styled.div`
  font-family: 'TWK Ghost';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 175%;
`

export const FloorPlanWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 32px;

  & svg {
    cursor: pointer;
  }
`

export const LinkWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 32px;
`

export const ModalWrapper = styled.div`
  display: ${props => props.isOpen ? 'flex' : 'none'};
  margin: auto;
  position: fixed;
  height: -webkit-fill-available;
  width: calc(100vw - 40px);
  z-index: 10;
  display: flex;
  justify-content: center;
  top: 50px;
   ${mediaMax('tabletLandscape')} {
    // width: 90vw;
    top: 80px;
  }
`

export const ModalContainer = styled.div`
  position: relative;
  max-width: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  ${mediaMax('tabletLandscape')} {
    width: 90vw;
  }
`

export const Content = styled.div`
  position: relative;
`

export const CloseModalButton = styled.span`
  position: absolute;
  right: -10px;
  top: -10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`

export const SortIconWrapper = styled.div`
  display: flex;
  transform: ${props => props.isAsc ? 'rotate(180deg)' : 'rotate(0deg)'};
`

export const NotificationHintWrapper = styled.div`
  animation: fadeInOut 3s linear;
  height: calc(100vh - 40px);
  width: calc(100vw - 40px);
  position: absolute;
  z-index: 10;
  top: 0;
  display: grid;
  place-items: center;
  background: #efe8dc;
`

export const TextWrapper = styled.div`
padding: 8px;
border: 1px solid #47341E;
width: fit-content;
border-radius: 16px;
`

