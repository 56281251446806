import React from "react"
import { MouseWheel } from "../pages/amenities/promo/index.style"
import ScrollIcon from "./icons/scroll-icon"
import * as Styled from "./index.style"

import { IntroContainer, Image } from "./index.style"

const Intro = ({ withoutScrollIcon, isFullHeight = true, query }) => {
  return (
    <IntroContainer isFullHeight={isFullHeight}>
      <Image fluid={query.file.childImageSharp.fluid} alt={""} />
      {withoutScrollIcon || !isFullHeight ? null : (
        <Styled.SrollIconWrapper>
          <ScrollIcon />
          <Styled.Text>Scroll</Styled.Text>
          <MouseWheel />
        </Styled.SrollIconWrapper>
      )}
    </IntroContainer>
  )
}

export default Intro
