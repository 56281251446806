import React, { useState, useCallback, useEffect } from "react"
import { Logo } from "../../icons/logo"
import {
  Map as ReactMapGL,
  NavigationControl,
  Marker,
  Popup
} from "react-map-gl"
import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import {
  MarkerContent,
  MarkerLabel,
  MapWrapper,
  LogoWrapper,
  MarkerWrapper
} from "./index.styled"
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default

const navControlStyle = {
  right: 10,
  top: 10
}

const MapContent = ({ content, selectedPoI, setSelectedPoI }) => {
  const [settings] = useState({
    scrollZoom: false,
    keyboard: false
  })

  const ref = React.useRef()

  const [viewport] = useState({
    latitude: content.Center.latitude,
    longitude: content.Center.longitude,
    zoom: content.ZoomLevel,
    width: "100%",
    height: "100%"
  })

  useEffect(() => {
    if (ref.current && selectedPoI) {
      ref.current.flyTo({
        center: selectedPoI.geometry.coordinates,
        essential: true
      })
    }
  }, [selectedPoI])

  return (
    <MapWrapper>
      <ReactMapGL
        {...settings}
        ref={ref}
        initialViewState={viewport}
        mapStyle={"mapbox://styles/dboxcartifact/clcl0g8ah000015tmttunnxnq"}
        mapboxAccessToken={
          "pk.eyJ1IjoiZGJveGNhcnRpZmFjdCIsImEiOiJja28zamNyMzUwYW5vMm9xd2JtMWYxeWQzIn0.CCNGewPeaiydOFITf8Rvyg"
        }
      >
        <NavigationControl style={navControlStyle} />
        <Marker
          key={`marker-ruby`}
          latitude={40.748182507212952}
          longitude={-73.995095096345779}
        >
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
        </Marker>
        <Marker key={`marker-FIT`} latitude={40.74712} longitude={-73.99455}>
          <MarkerWrapper>FIT</MarkerWrapper>
        </Marker>
        {content.PoI.map((item, key) => (
          <Marker
            key={`marker-${key}`}
            latitude={item?.geometry?.coordinates[1]}
            longitude={item?.geometry?.coordinates[0]}
            anchor="left"
            onClick={event => {
              event.originalEvent.preventDefault()
              event.originalEvent.stopPropagation()
              setSelectedPoI(item)
            }}
          >
            <MarkerContent />

            <MarkerLabel>{item.properties.NAME}</MarkerLabel>
          </Marker>
        ))}
        {selectedPoI && selectedPoI.properties.NAME ? (
          <Popup
            className={"popup-content"}
            latitude={selectedPoI?.geometry?.coordinates?.[1]}
            longitude={selectedPoI?.geometry?.coordinates?.[0]}
            onClose={() => setSelectedPoI(null)}
          >
            <h3>{selectedPoI?.properties.NAME}</h3>
            {selectedPoI.properties.ADDRESSFOR ? (
              <p>{selectedPoI?.properties.ADDRESSFOR}</p>
            ) : null}
          </Popup>
        ) : null}
      </ReactMapGL>
    </MapWrapper>
  )
}

export default MapContent
