import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { PhotoCategory } from "./photo-category"
import styled from "styled-components"
import { StyledContainer } from "./index.style"
import useDeviceDetect from "../../hooks/use-device-detect"

const category = {
  dining: {
    title: "Dining",
    description:
      "You will never grow bored dining in Chelsea. With classic neighborhood bistros and casual takeout, rooftop gardens and farm fresh brunches, the possibilities are endless.",
    imageConfig: {
      dining_empire_diner: {
        description: "Empire Diner",
        isFull: true
      },
      dining_gallow_green: {
        description: "Gallow Green"
      },
      dining_empire_diner_two: {
        description: "Empire Diner"
      },
      dining_cookshop: {
        description: "Cookshop",
        isFull: true
      },
      dining_tacombi: {
        description: "Tacombi"
      },
      dining_skirt_steak: {
        description: "Skirt Steak"
      },
      dining_gramercy_tavern: {
        description: "Gramercy Tavern"
      },
      dining_loulou: {
        description: "Loulou"
      },
      dining_stumptown: {
        description: "Stumptown",
        isFull: true
      }
    }
  },
  parks: {
    title: "Parks",
    description:
      "A park-rich neighborhood with tree-lined streets and easy access to vast green spaces such as the High Line and Hudson River Park, Chelsea is flush with nature.",
    imageConfig: {
      parks_the_high_line: {
        description: "The High Line",
        isFull: true
      },
      parks_the_high_line_two: {
        description: "",
        isFull: true
      },
      parks_the_high_line_three: {
        description: "",
        isFull: true
      },
      parks_shed: {
        description: "The Shed"
      },
      parks_the_high_line_hotel: {
        description: "The High Line Hotel"
      },
      parks_piropo_flowers: {
        description: "Piropo Flowers"
      },
      parks_whitney_museum_of_american_art: {
        description: "Whitney Museum of American Art"
      },
      parks_little_island: {
        description: "Little Island",
        isFull: true
      }
    }
  },
  shopping: {
    title: "Shopping",
    description:
      "Chelsea is home to some of the most eclectic shops in the world, with fashion boutiques and vintage thrift stores, as well as artisan marketplaces and contemporary design.",
    imageConfig: {
      shopping_jung_lee: {
        description: "Jung Lee",
        isFull: true
      },
      shopping_chelsea_market: {
        description: "Chelsea Market"
      },
      shopping_louis_vuitton: {
        description: "Louis Vuitton"
      },
      shopping_camp: {
        description: "CAMP",
        isFull: true
      }
    }
  },
  transportation: {
    title: "Transportation",
    description:
      "Ruby is within easy access of major public transportation systems, with close proximity to Moynihan Train Hall and Penn Station as well as numerous subway lines.",
    imageConfig: {
      transportation_moynihan_train_hall: {
        description: "Moynihan Train Hall",
        isFull: true
      },
      transportation_moynihan_train_hall_two: {
        description: "",
        isFull: true
      }
    }
  }
}

const joinFluidToImageConfig = (imageConfig = {}, fluids = {}) => {
  const keys = Object.keys(imageConfig)

  return keys.map(item => {
    return { ...imageConfig[item], ...fluids[item].childImageSharp }
  })
}

export const NeighborhoodImages = () => {
  const { isMobile } = useDeviceDetect()

  const dining = graphql`
    query {
      dining_empire_diner: file(
        relativePath: { eq: "neighborhood/places/dining_empire_diner.jpg" }
      ) {
        name
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      dining_empire_diner: file(
        relativePath: { eq: "neighborhood/places/dining_empire_diner.jpg" }
      ) {
        name
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      dining_gallow_green: file(
        relativePath: { eq: "neighborhood/places/dining_gallow_green.jpg" }
      ) {
        name
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      dining_empire_diner_two: file(
        relativePath: { eq: "neighborhood/places/dining_empire_diner_two.jpg" }
      ) {
        name
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      dining_cookshop: file(
        relativePath: { eq: "neighborhood/places/dining_cookshop.jpg" }
      ) {
        name
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      dining_tacombi: file(
        relativePath: { eq: "neighborhood/places/dining_tacombi.jpg" }
      ) {
        name
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      dining_skirt_steak: file(
        relativePath: { eq: "neighborhood/places/dining_skirt_steak.jpg" }
      ) {
        name
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dining_gramercy_tavern: file(
        relativePath: { eq: "neighborhood/places/dining_gramercy_tavern.jpg" }
      ) {
        name
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      dining_loulou: file(
        relativePath: { eq: "neighborhood/places/dining_loulou.jpg" }
      ) {
        name
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      dining_stumptown: file(
        relativePath: { eq: "neighborhood/places/dining_stumptown.jpg" }
      ) {
        name
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      # parks start

      parks_the_high_line: file(
        relativePath: { eq: "neighborhood/places/parks_the_high_line.jpg" }
      ) {
        name
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      parks_the_high_line_two: file(
        relativePath: { eq: "neighborhood/places/parks_the_high_line_two.jpg" }
      ) {
        name
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      parks_the_high_line_three: file(
        relativePath: {
          eq: "neighborhood/places/parks_the_high_line_three.jpg"
        }
      ) {
        name
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      parks_shed: file(
        relativePath: { eq: "neighborhood/places/parks_shed.jpg" }
      ) {
        name
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      parks_the_high_line_hotel: file(
        relativePath: {
          eq: "neighborhood/places/parks_the_high_line_hotel.jpg"
        }
      ) {
        name
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      parks_piropo_flowers: file(
        relativePath: { eq: "neighborhood/places/parks_piropo_flowers.jpg" }
      ) {
        name
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      parks_whitney_museum_of_american_art: file(
        relativePath: {
          eq: "neighborhood/places/parks_whitney_museum_of_american_art.jpg"
        }
      ) {
        name
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      parks_little_island: file(
        relativePath: { eq: "neighborhood/places/parks_little_island.jpg" }
      ) {
        name
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      # parks end

      #shopping start
      shopping_jung_lee: file(
        relativePath: { eq: "neighborhood/places/shopping_jung_lee.jpg" }
      ) {
        name
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      shopping_chelsea_market: file(
        relativePath: { eq: "neighborhood/places/shopping_chelsea_market.jpg" }
      ) {
        name
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      shopping_louis_vuitton: file(
        relativePath: { eq: "neighborhood/places/shopping_louis_vuitton.jpg" }
      ) {
        name
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      shopping_camp: file(
        relativePath: { eq: "neighborhood/places/shopping_camp.jpg" }
      ) {
        name
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      # transportation start

      transportation_moynihan_train_hall: file(
        relativePath: {
          eq: "neighborhood/places/transportation_moynihan_train_hall.jpg"
        }
      ) {
        name
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      transportation_moynihan_train_hall_two: file(
        relativePath: {
          eq: "neighborhood/places/transportation_moynihan_train_hall_two.jpg"
        }
      ) {
        name
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      # transportation end
    }
  `
  const diningImages = useStaticQuery(dining)
  const categoryImages = {
    dining: diningImages,
    parks: diningImages,
    shopping: diningImages,
    transportation: diningImages
  }

  return (
    <StyledContainer isMobile={isMobile}>
      {Object.entries(category).map(([categoryKey, categoryInfo], i) => {
        const images = joinFluidToImageConfig(
          categoryInfo.imageConfig,
          categoryImages[categoryKey]
        )
        return (
          <PhotoCategory
            key={i}
            title={categoryInfo.title}
            description={categoryInfo.description}
            images={images}
          />
        )
      })}
    </StyledContainer>
  )
}
