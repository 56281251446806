import React from "react"
import {
  MapLegendWrapper,
  MapLegendAccordionItemTitle,
  MapLegendPoIWrapper,
  MapLegendPoI,
  MapLegendAccordion,
  MapLegendDiningPoIWrapper,
  MapLegendDiningPoIBlock,
  SubwayLinesTitle
} from "./index.styled"
import SmoothCollapse from "react-smooth-collapse"
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel
} from "react-accessible-accordion"
import useDeviceDetect from "../../hooks/use-device-detect"

const MapLegend = ({
  currentCategory,
  setCurrentCategory,
  PoI,
  currentPoI,
  setCurrentPoI
}) => {
  const { isMobile } = useDeviceDetect()

  const categories = [
    { name: "dining" },
    { name: "cultureAndEntertainment" },
    { name: "florists" },
    { name: "parks" },
    { name: "grocery" },
    // { name: "museums" },
    { name: "neighborhoods" },
    { name: "transportation" }
  ]

  const convertCategoryName = name => {
    switch (name) {
      case "cultureAndEntertainment":
        return "Culture & Entertainment"
      case "florists":
        return "Florists & Plant Shops"
      case "grocery":
        return "Grocery & Shopping"
      case "neighborhoods":
        return "Surrounding Neighborhoods"
      default:
        return name
    }
  }
  React.useEffect(() => {}, [currentCategory])

  return (
    <MapLegendWrapper>
      <MapLegendAccordion preExpanded={["dining"]}>
        {categories.map((category, index) => (
          <AccordionItem
            style={{
              transition: "margin 1s ease-in-out",
              margin:
                currentCategory === category.name && index ? "48px 0px" : "",
              marginBottom: currentCategory === category.name && "48px"
            }}
            uuid={category.name}
            key={`category-${index}`}
          >
            <AccordionItemHeading
              onClick={() => setCurrentCategory(category.name)}
            >
              <MapLegendAccordionItemTitle
                $active={currentCategory === category.name}
              >
                {convertCategoryName(category.name)}
              </MapLegendAccordionItemTitle>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {category.name === "dining" && (
                <SmoothCollapse
                  heightTransition="1s ease"
                  expanded={currentCategory === category.name}
                >
                  <MapLegendDiningPoIWrapper isMobile={isMobile}>
                    <MapLegendDiningPoIBlock>
                      {PoI[category.name]
                        .sort((a, b) =>
                          a.properties.NAME.localeCompare(b.properties.NAME)
                        )
                        .slice(0, Math.ceil(PoI[category.name].length / 2))
                        .map(item => (
                          <MapLegendPoI
                            key={item.properties.NAME}
                            onClick={() => setCurrentPoI(item)}
                            $active={
                              currentPoI &&
                              currentPoI.properties.NAME &&
                              currentPoI.properties.NAME ===
                                item.properties.NAME
                            }
                          >
                            {item.properties.NAME}
                          </MapLegendPoI>
                        ))}
                    </MapLegendDiningPoIBlock>
                    <MapLegendDiningPoIBlock>
                      {PoI[category.name]
                        .sort((a, b) =>
                          a.properties.NAME.localeCompare(b.properties.NAME)
                        )
                        .slice(Math.floor(PoI[category.name].length / 2) + 1)
                        .map((item, index) => (
                          <MapLegendPoI
                            key={item.properties.NAME}
                            onClick={() => setCurrentPoI(item)}
                            $active={
                              currentPoI &&
                              currentPoI.properties.NAME &&
                              currentPoI.properties.NAME ===
                                item.properties.NAME
                            }
                          >
                            {item.properties.NAME}
                          </MapLegendPoI>
                        ))}
                    </MapLegendDiningPoIBlock>
                  </MapLegendDiningPoIWrapper>
                </SmoothCollapse>
              )}

              <MapLegendPoIWrapper isMobile={isMobile}>
                <SmoothCollapse
                  heightTransition=".5s ease"
                  expanded={currentCategory === category.name}
                >
                  {category.name === "transportation" && (
                    <>
                      {PoI[category.name].map(item => (
                        <MapLegendPoI
                          key={item.properties.NAME}
                          onClick={() => setCurrentPoI(item)}
                          $active={
                            currentPoI &&
                            currentPoI.properties.NAME &&
                            currentPoI.properties.NAME === item.properties.NAME
                          }
                        >
                          {item.properties.NAME.startsWith(
                            "Penn Station (Amtrak, Long Island Railroad, New Jersey Transit)"
                          ) ? (
                            <div>
                              Penn Station
                              <br />
                              (Amtrak, Long Island Railroad, New Jersey Transit)
                              <br />
                            </div>
                          ) : (
                            item.properties.NAME
                          )}
                        </MapLegendPoI>
                      ))}
                      <SubwayLinesTitle>
                        Nearby Subway lines: A, C, E, 1, 2, 3, 7
                      </SubwayLinesTitle>
                    </>
                  )}
                </SmoothCollapse>
                <SmoothCollapse
                  heightTransition="1s ease"
                  expanded={currentCategory === category.name}
                >
                  {category.name !== "dining" &&
                    category.name !== "transportation" &&
                    PoI[category.name]
                      .sort((a, b) =>
                        a.properties.NAME.localeCompare(b.properties.NAME)
                      )
                      .map((item, index) => (
                        <MapLegendPoI
                          key={item.properties.NAME}
                          onClick={() => setCurrentPoI(item)}
                          $active={
                            currentPoI &&
                            currentPoI.properties.NAME &&
                            currentPoI.properties.NAME === item.properties.NAME
                          }
                        >
                          {item.properties.NAME}
                        </MapLegendPoI>
                      ))}
                </SmoothCollapse>
              </MapLegendPoIWrapper>
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </MapLegendAccordion>
    </MapLegendWrapper>
  )
}

export default MapLegend
