import React from "react"
import { Photo } from "./photo"
import styled from "styled-components"

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
`

export const PhotoList = ({ images }) => {
  return (
    <Container>
      {images.map((imageData, i) => (
        <Photo
          key={i}
          fluid={imageData.fluid}
          isFull={imageData.isFull}
          description={imageData.description}
        />
      ))}
    </Container>
  )
}
