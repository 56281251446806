import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import ViewAnimation from "../animation/view-animation"
const Container = styled.figure`
  grid-column: ${props => (props.isFull ? "span 2" : "span 1")};
`

const StyledImage = styled(props => <Img {...props} />)`
  width: 100%;
  height: auto;
  display: block;
`

const Figcaption = styled.figcaption`
  font-weight: 400;
  font-size: 12px;
  line-height: 175%;
  color: #47341e;
  font-style: italic;
  margin: 10px 0;
`

export const Photo = ({ fluid, description, isFull }) => {
  return (
    <Container isFull={isFull}>
      <ViewAnimation>
        <StyledImage fluid={fluid} />
        <Figcaption>{description}</Figcaption>
      </ViewAnimation>
    </Container>
  )
}
