import styled from "styled-components"

export const MapWrapper = styled.div`
  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
  }

  .mapboxgl-popup-content {
    background: #386641;
    color: #3c4e58;
    padding: 10px;
    line-height: 18px;
    max-width: 240px;
    text-align: center;
    border-radius: 0;
  }
  .mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
    border-right-color: #386641;
  }
  .mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
    border-left-color: #386641;
  }
  .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    border-top-color: #386641;
  }
  .mapboxgl-popup-top-bottom .mapboxgl-popup-tip {
    border-bottom-color: #386641;
  }
  .mapboxgl-popup-close-button {
    display: none;
  }
  .mapboxgl-popup-content h3 {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 4px;
    color: #fff;
  }
  .mapboxgl-popup-content p {
    font-size: 12px;
    line-height: 18px;
    margin: 0;
    color: #fff;
  }

  .mapboxgl-canvas {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .mapboxgl-ctrl-bottom-left,
  .mapboxgl-ctrl-bottom-right {
    display: none;
  }
`

export const MarkerContent = styled.div`
  background-color: #386641;
  border-radius: 100%;
  content: "";
  height: 10px;
  width: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: visible;
  cursor: pointer;
`

export const MarkerLabel = styled.div`
  color: #3c4e59;
  font-weight: 500;
  position: absolute;
  white-space: nowrap;
  display: none;
`

export const LogoWrapper = styled.div`
  svg {
    width: 70px;
    color: #a1b38b;
  }
`

export const MarkerWrapper = styled.div`
  font-family: 'TWK Ghost';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #b18e53;
  opacity: 0.5;
`
