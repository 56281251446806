import React from "react"
import MapContent from "../map-content"

import { MapWrapper, MapContentWrapper, MapLegendWrapper } from "./index.style"
import MapLegend from "../map-legend"
import useDeviceDetect from "../../hooks/use-device-detect"

const PoI = {
  dining: require("../../data/neighborhood/neighborhood-dining.json").PoI,
  cultureAndEntertainment:
    require("../../data/neighborhood/culture-entertainment.json").PoI,
  florists: require("../../data/neighborhood/neighborhood-florists.json").PoI,
  grocery: require("../../data/neighborhood/neighborhood-grocery.json").PoI,
  museums: require("../../data/neighborhood/neighborhood-museums.json").PoI,
  parks: require("../../data/neighborhood/neighborhood-parks.json").PoI,
  neighborhoods:
    require("../../data/neighborhood/neighborhood-neighborhoods.json").PoI,
  transportation:
    require("../../data/neighborhood/neighborhood-transportation.json").PoI
}

const Map = ({ content }) => {
  const { isMobile } = useDeviceDetect()
  const [currentCategory, setCurrentCategory] = React.useState("dining")
  const [selectedPoI, setSelectedPoI] = React.useState(null)

  React.useEffect(() => setSelectedPoI(null), [currentCategory])

  return (
    <MapWrapper isMobile={isMobile} className="map-wrapper">
      <MapContentWrapper isMobile={isMobile}>
        <MapContent
          content={{
            Center: {
              latitude: 40.748182507212952,
              longitude: -73.995095096345779
            },
            ZoomLevel: 15,
            PoI: PoI[currentCategory]
          }}
          selectedPoI={selectedPoI}
          setSelectedPoI={setSelectedPoI}
        />
      </MapContentWrapper>
      <MapLegendWrapper isMobile={isMobile}>
        <MapLegend
          PoI={PoI}
          currentCategory={currentCategory}
          setCurrentCategory={setCurrentCategory}
          currentPoI={selectedPoI}
          setCurrentPoI={setSelectedPoI}
        />
      </MapLegendWrapper>
    </MapWrapper>
  )
}

export default Map
