import React from "react"
import useDeviceDetect from "../../hooks/use-device-detect"
import ViewAnimation from "../animation/view-animation"
import { StyledContainer, StyledText } from "./index.style"

const PageDescription = ({ text }) => {
  const { isMobile } = useDeviceDetect()
  return (
    <ViewAnimation>
      <StyledContainer>
        <StyledText $isMobile={isMobile}>{text}</StyledText>
      </StyledContainer>
    </ViewAnimation>
  )
}

export default PageDescription
