import styled from "styled-components"

export const StyledContainer = styled.section`
  min-height: 475px;
  max-width: 720px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`

export const StyledText = styled.p`
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  padding: ${({ $isMobile }) => $isMobile && '24px'};
  text-indent: ${({ $isMobile }) => !$isMobile && '100px'};
  text-align:${({ $isMobile }) => $isMobile && 'center'};
  color: #47341e;
`